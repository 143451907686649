import { Box, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { IDashboardInsights } from '../../../redux/actions/TicketsManagement/schema';
import { IState } from '../../../redux/reducers/rootReducers';
interface IHomeProps {
    dispatch:any;
    dashboardSummary:IDashboardInsights|undefined;
}
const CountCards = ({dashboardSummary,dispatch}:IHomeProps) => {
    const {dueToday=0, dueInWeek=0, overdueToday=0, resolvedToday=0, ticketStatus} = dashboardSummary||{};
    // const {newTicketCount=0,opeanTicketCount=0,dataList} = dashboardSummary || {};
  return (
    <Box>
        <Typography gutterBottom>Tickets In Progress</Typography>
        <Grid container spacing={2}>
            <Grid item xs={2} md={2}>
                <Box className="home-countCard"  sx={{backgroundColor:"#ee638b"}}>
                    <Typography variant='h4'>{overdueToday}</Typography>
                    <Typography className="datalabel">Overdue Today</Typography>
                </Box>
            </Grid>

            <Grid item xs={2} md={2}>
                <Box className="home-countCard"  sx={{backgroundColor:"#d1da90"}}>
                    <Typography variant='h4'>{dueToday}</Typography>
                    <Typography className="datalabel">Due Today</Typography>
                </Box>
            </Grid>

            <Grid item xs={2} md={2}>
                <Box className="home-countCard"  sx={{backgroundColor:"#c7deb3"}}>
                    <Typography variant='h4'>{dueInWeek}</Typography>
                    <Typography className="datalabel">Due in 7 days</Typography>
                </Box>
            </Grid>

            <Grid item xs={2} md={2}>
                <Box className="home-countCard" sx={{backgroundColor:"#b6dfff"}}>
                    <Typography variant='h4'>{ticketStatus?.newTicketCount || 0}</Typography>
                    <Typography className="datalabel">New Tickets</Typography>
                </Box>
            </Grid>

            <Grid item xs={2} md={2}>
                <Box className="home-countCard"  sx={{backgroundColor:"#dbf9f0"}}>
                    <Typography variant='h4'>{ticketStatus?.openTicketCount || 0}</Typography>
                    <Typography className="datalabel">Open Tickets</Typography>
                    <Box className="countTooltip">
                      <Typography><span className="simple-icon icon-info"></span></Typography>
                        <Box className="countTooltip-body">
                            <Box className="white-bg">
                                <ul>
                                    {Object.keys(ticketStatus?.dataList||{}).map((i)=>{
                                        return <li key={i}>{i} : {ticketStatus?.dataList?.[i]?.count ||0 }</li>
                                    })}
                                </ul>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={2} md={2}>
                <Box className="home-countCard"  sx={{backgroundColor:"#b3dec1"}}>
                    <Typography variant='h4'>{resolvedToday}</Typography>
                    <Typography className="datalabel">Resolved Today</Typography>
                </Box>
            </Grid>
        </Grid>
    </Box>
  )
}


const mapStateToProps = (state:IState) => {
    return ({
        dashboardSummary : state?.TicketsReducer?.dashboardSummary,
    })
  };
export default connect(mapStateToProps)(CountCards);